import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ExternalLinks from '../components/ExternalLinks';
import Layout from '../components/Layout';

export const ReleasePostTemplate = ({ release }) => {
  const {
    releaseTitle,
    date,
    image,
    credits,
    songs,
    embed,
    notes,
    bandcampURL,
    iTunesURL,
    spotifyURL,
  } = release;
  return (
    <section className="flex flex-column">
      <h1 className="title thearcher">{releaseTitle}</h1>
      <div className="mb4 flex-l flex-wrap flex-row items-start">
        <div className="flex flex-column mr4-l mb4">
          <img
            className="mb2 w-100 w-auto-l"
            src={image.childImageSharp.fluid.src}
            alt={releaseTitle}
          />
          <span className="i f6">{date}</span>
        </div>
        {embed && <div dangerouslySetInnerHTML={{ __html: embed }} />}
      </div>
      {(bandcampURL || spotifyURL || iTunesURL) && (
        <div>
          {ExternalLinks({
            bandcampURL,
            spotifyURL,
            itunesURL: iTunesURL,
          })}
        </div>
      )}
      {notes && (
        <>
          <div dangerouslySetInnerHTML={{ __html: notes }} className="mv3" />
        </>
      )}
      <h3 className="i">Lyrics</h3>
      <div>
        <ol className="list ma0 pa0 TrackListing__list flex flex-column flex-row-l flex-wrap">
          {songs.map(({ frontmatter }) => (
            <li
              className="flex flex-column pa2 border-box mw6-l w-100"
              key={frontmatter.songTitle}
            >
              <h2 className="mv0 ml4">{frontmatter.songTitle}</h2>
              {(frontmatter.credits || frontmatter.lyrics) && (
                <div>
                  <div
                    className="f5"
                    dangerouslySetInnerHTML={{ __html: frontmatter.lyrics }}
                  />
                  {frontmatter.credits && (
                    <p className="f6 i">Credits: {frontmatter.credits}</p>
                  )}
                </div>
              )}
            </li>
          ))}
        </ol>
        {credits && (
          <>
            <h3 className="i">Notes</h3>
            <div dangerouslySetInnerHTML={{ __html: credits }} />
          </>
        )}
      </div>
    </section>
  );
};

ReleasePostTemplate.propTypes = {
  release: PropTypes.object.isRequired,
};

const ReleasePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ReleasePostTemplate release={post.frontmatter} />
    </Layout>
  );
};

ReleasePost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReleasePost;

export const ReleasePostQuery = graphql`
  query ReleasePost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        releaseTitle
        date(formatString: "MMMM DD, YYYY")
        embed
        notes
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              src
            }
          }
        }
        credits
        bandcampURL
        iTunesURL
        spotifyURL
        songs {
          frontmatter {
            songTitle
            lyrics: markdownField(from: "lyrics")
            credits
          }
        }
      }
    }
  }
`;
